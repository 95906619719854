import React from 'react'
import { Trans } from '@lingui/macro';
import { StaticImage } from "gatsby-plugin-image"

const OurTeam = () => {
  return (
   <section className='ourteam-section'>
    <div className='container'>
      <div className='columns'>
        <div className='column  is-4'>
          <div className='ourteam-heading'>
            <h2><Trans>Notre équipe</Trans></h2>
            <p><Trans>Dédiée à la réussite de nos membres</Trans></p>
          </div>
        </div>
        <div className='column is-8'>
          <div className='columns team-box-wrap'>
            <div className='column is-4'>
              <div className='team-box'>
                <div className='team-details'>
                  <div className='member-img'>
                    <StaticImage
                      src='../images/TeamMember/kara.png'
                      alt='Kara'
                      title="Kara Diaby, CEO de Repat Africa"
                     />
                  </div>
                  <div className='member-name'>
                    <h3>Kara</h3>
                    <p>CEO</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='column is-4'>
              <div className='team-box'>
                <div className='team-details'>
                  <div className='member-img'>
                    <StaticImage
                      src='../images/TeamMember/imane.png'
                      alt='Imane'
                      title="Imane, CMO de Repat Africa"
                     />
                  </div>
                  <div className='member-name'>
                    <h3>Imane</h3>
                    <p>CPO</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='column is-4'>
              <div className='team-box'>
                <div className='team-details'>
                  <div className='member-img'>
                    <StaticImage
                      src='../images/TeamMember/mounir.png'
                      alt='Mounir'
                      title="Mounir, Advisor chez Repat Africa"
                     />
                  </div>
                  <div className='member-name'>
                    <h3>Mounir</h3>
                    <p>Board</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='column is-4'>
              <div className='team-box'>
                <div className='team-details'>
                  <div className='member-img'>
                    <StaticImage
                      src='../images/TeamMember/aissatou.png'
                      alt='Aïssatou'
                      title="Aïssatou, responsable des relations publiques chez Repat Africa"
                     />
                  </div>
                  <div className='member-name'>
                    <h3>Aïssatou</h3>
                    <p><Trans>Responsable des Relations publiques</Trans></p>
                  </div>
                </div>
              </div>
            </div>

            <div className='column is-4'>
              <div className='team-box'>
                <div className='team-details'>
                  <div className='member-img'>
                    <StaticImage
                      src='../images/TeamMember/fatoumata.png'
                      alt='Fatoumata'
                      title="Fatoumata, Responsable évènementiel chez Repat Africa"
                     />
                  </div>
                  <div className='member-name'>
                    <h3>Fatoumata</h3>
                    <p><Trans>Responsable Formations</Trans></p>
                  </div>
                </div>
              </div>
            </div>

            <div className='column is-4'>
              <div className='team-box'>
                <div className='team-details'>
                  <div className='member-img'>
                    <StaticImage
                      src='../images/TeamMember/alice.png'
                      alt='Alice'
                      title="Alice, Amélioration continue chez Repat Africa"
                     />
                  </div>
                  <div className='member-name'>
                    <h3>Alice</h3>
                    <p><Trans>Responsable des Opérations</Trans></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
   </section>
  )
}

export default OurTeam
