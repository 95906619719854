import React from 'react'
import { Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';

export const Home = () => {
  return (
    <>

      <div className="container home-container">
        <div className="columns">
          <div className="column">
            <h1 className="title-home">
              <Trans>
                <span className="title-home title-home-africa">L'Afrique</span>
                <br className="br-desktop" />
                <span className="title-home title-home-is-calling">nous&nbsp;</span>
                <span className="title-home title-home-you">appelle</span>
              </Trans>
            </h1>
              <div id="repat-home-mobile-img">
                <StaticImage
                  src='../images/homePage-img.webp'
                  alt='Image accueil'
                  placeholder="blurred"
                  title="Image de la home page de Repat Africa"
                  formats={['auto', 'webp', 'avif']}
                  loading="eager"
                />
              </div>

            <h2 className="subtitle-home">
              <Trans>
                Vivez, Investissez, Prospérez !
              </Trans>
            </h2>
            <p className="text-home has-text-justified">
              <Trans>
                L'avenir s'écrit en <strong>Afrique</strong> et nous sommes au cœur de cette transformation.<br />
                Bienvenue chez Repat Africa, là où les <strong>actions</strong> parlent plus que les mots.
              </Trans>
            </p>
            <div className="flex mt-6 membershipButton">
              <a className="button-membership" href="https://eu.jotform.com/form/223365228500348" target="_blank" rel="noopener noreferrer"><Trans>Devenir membre</Trans></a>
            </div>
          </div>

          <div className="column home-img-wrapper">
            <StaticImage
              src='../images/homePage-img.webp'
              alt='Image accueil'
              title="Image de la home page de Repat Africa"
              id="repat-home-desktop-img"
              loading="eager"
              placeholder="blurred"
              formats={['auto', 'webp', 'avif']}
            />
          </div>

        </div>
      </div>
    </>
  )
}

export default Home;
