import React, { useEffect } from 'react'
import { I18nProvider } from '@lingui/react';
import Layout from '../components/Layout.js';
import Seo from "../components/Seo.js";

import img from '../images/repat-home.png';
import { useIntl } from 'gatsby-plugin-intl';
import i18n from '../i18n.js';
import LegalNoticeTab from '../components/LegalNoticeTab.js';

const Legal = () => {
  const intl = useIntl();

  useEffect(() => {
    i18n.activate(intl.locale);
  }, [intl.locale]);

  return (
    <div>
      {/* <I18nProvider language={locale} catalogs={catalogs}> */}
      <I18nProvider i18n={i18n}>
        <Layout locale={intl.locale}>
          <Seo
            lang={intl.locale}
            title="Mentions légales"
            description="Mentions légales de Repat Africa"
            image={img}
            robots="index"
          />
          <LegalNoticeTab />
        </Layout>
      </I18nProvider>
      {/* </I18nProvider> */}
    </div>
  )
}

export default Legal;
