import React from 'react'
import { Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import VideoFacade from './VideoFacade';

const Benefits = () => {
  return (
    <section className=" benefits-section">
      {/* Benefits of using our APP */}
      <div className='benefits-main-section'>
      <div className="container">

        <div className='benefits'>
          <div className='downlordApp'>
              <div className='app-haeding'>
                <h4><Trans>Bientôt disponible</Trans></h4>
                <p className="has-text-justified">
                  <Trans>
                    Elle permet aux membres de rester connectés, de s'inscrire aux évènements et de suivre leurs investissements.
                  </Trans>
                </p>
              </div>
              <div className='appButton'>
                <button>
                  <StaticImage
                    src='../images/AppStore.png'
                    alt="Apple Store"
                   />
                </button>
                <button>
                  <StaticImage
                    src='../images/GooglePlay.png'
                    alt="Google play"
                   />
                </button>
              </div>
          </div>
          <div className='benefitsImage'>
            <StaticImage
              src='../images/benefitsimage.webp'
              alt="Benefits"
            />
          </div>
          <div className='benefitsApp'>
            <div className="section-content feedbacks-heading">
              <h2><Trans>Profitez de l'application</Trans></h2>
            </div>
            <div className="benefits-details">
              <div className="feature-details">
                <div className='feature-img'>
                  <StaticImage
                    src='../images/Feature1.png'
                    alt="Benefits"
                  />
                </div>
                <div className='featurecontant'>
                  <p><Trans>Connectez avec tous les membres</Trans></p>
                  <span className="has-text-justified"><Trans>Rencontrez instantanément tous les membres de la communauté : discutez, collaborez et partagez des ressources à travers un réseau étendu et diversifié.</Trans></span>
                </div>
              </div>
              <div className="feature-details">
                <div className='feature-img'>
                  <StaticImage
                    src='../images/Feature2.png'
                    alt="Benefits"
                   />
                </div>
                <div className='featurecontant'>
                  <p><Trans>Préparez votre arrivée en Afrique</Trans></p>
                  <span className="has-text-justified"><Trans>Grâce au moteur de recherche, trouvez les membres et partenaires présents en Afrique. Contactez-les et préparez vos rendez-vous avant votre arrivée.</Trans></span>
                </div>
              </div>
              <div className="feature-details">
                <div className='feature-img'>
                  <StaticImage
                    src='../images/Feature3.png'
                    alt="Benefits"
                   />
                </div>
                <div className='featurecontant'>
                  <p><Trans>Inscrivez-vous aux évènements</Trans></p>
                  <span className="has-text-justified"><Trans>Retrouvez le calendrier des évènements et inscrivez-vous en un clic.</Trans></span>
                </div>
              </div>
              <div className="feature-details">
                <div className='feature-img'>
                  <StaticImage
                    src='../images/Feature4.png'
                    alt="Benefits"
                   />
                </div>
                <div className='featurecontant'>
                  <p><Trans>Suivez vos investissements</Trans></p>
                  <span className="has-text-justified"><Trans>Suivez l'évolution et les performances des entreprises dans lesquelles vous avez investies.</Trans></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
       {/* Diaspora */}
       <div className='diaspora-section'>
        <div className="container">
          <div className='columns is-vcentered is-centered'>
            <div className='diaspora-details column'>
              <h2>L'Essor de la Diaspora</h2>
              <p><Trans>Un film produit par Repat Africa. Bientôt disponible sur YouTube</Trans></p>
            </div>
            <div className="column">
              <VideoFacade
                videoLink="https://www.youtube.com/embed/W0giMscUg-c"
                videoTitle="L'Essor de la Diaspora"
              />
            </div>

          </div>
        </div>

      </div>
      {/* Ourbenefits */}
      <div className=" Ourbenefits">
        <div className='container'>
          <div className='app-haeding section-content feedbacks-heading'>
            <h2><Trans>Nos avantages</Trans></h2>
            <p><Trans>Vous serez au premier rang pour participer au développement économique du continent.</Trans></p>
          </div>
          <div className='ourbenefits-option'>
            <div className='ourbenefitslist'>
              <div className='ourbenefitslistOne'>
                <span>01</span>
                <h5><Trans>Réseau professionnel élargi</Trans></h5>
                <p className="has-text-justified"><Trans>Connectez-vous à une communauté de professionnels et d'entrepreneurs en Afrique, partageant vos ambitions et vos valeurs. 🤝 </Trans></p>
              </div>
              <div className='ourbenefitslistOne'>
                <span>02</span>
                <h5><Trans>Opportunités d'investissement</Trans></h5>
                <p className="has-text-justified"><Trans>Accédez à des investissements exclusifs dans des entreprises africaines prometteuses. 🧱</Trans></p>
              </div>
            </div>
            <div className='ourbenefitslist'>
            <div className='ourbenefitslistOne'>
                <span>03</span>
                <h5><Trans>Évènements et rencontres exclusifs</Trans></h5>
                <p className="has-text-justified"><Trans>Participez aux voyages d'affaires en Afrique, aux afterworks, aux dîners intimistes et aux évènements en ligne. 🛫 </Trans> </p>
              </div>
              <div className='ourbenefitslistOne'>
                <span>04</span>
                <h5><Trans>Développement personnel et professionnel</Trans></h5>
                <p className="has-text-justified"><Trans>Bénéficiez de formations, de mentorat et de ressources pour développer votre carrière en Afrique. 🚀 </Trans></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Benefits
