import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

const PrivacyNotice = () => {
  return (
    <section className='PrivacyNotice-section'>
      <div className='container'>
        <h1><Trans>Politique de confidentialité</Trans></h1>

        <p><Trans>Notre application nécessite l'accès à la caméra pour prendre des photos et le multimédia.</Trans></p>

        <h2><Trans>Informations que nous collectons :</Trans></h2>
        <ul>
          <li><Trans>Accès à la caméra pour prendre des photos</Trans></li>
        </ul>

        <h2><Trans>Comment nous utilisons vos informations :</Trans></h2>
        <ol>
          <li><Trans>Les données de la caméra sont utilisées uniquement pour prendre des photos</Trans></li>
          <li><Trans>Nous ne stockons pas et ne transmettons pas vos données de caméra</Trans></li>
          <li><Trans>Nous ne partageons pas vos informations avec des tiers</Trans></li>
        </ol>

        <h2><Trans>Contactez-nous :</Trans></h2>
        <p><Trans>Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à hello[at]repat.africa.</Trans></p>

        <p><Trans>Dernière mise à jour :</Trans> 09/03/2025</p>
      </div>
    </section>
  )
}

export default PrivacyNotice;
