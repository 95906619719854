import React, { useEffect } from 'react'
import { I18nProvider } from '@lingui/react';
import Layout from '../components/Layout.js';
import Seo from "../components/Seo.js";
import PrivacyNotice from '../components/PrivacyNotice.js'

import img from '../images/repat-home.png';
import { useIntl } from 'gatsby-plugin-intl';
import i18n from '../i18n.js';


const Privacy = () => {
  const intl = useIntl();

  useEffect(() => {
    i18n.activate(intl.locale);
  }, [intl.locale]);

  return (
    <div>
      <I18nProvider i18n={i18n}>
        <Layout locale={intl.locale}>
          <Seo
            lang={intl.locale}
            title="Politique de confidentialité"
            description="Politique de confidentialité de l'application Repat Africa"
            image={img}
            robots="index"
          />
          <PrivacyNotice />
        </Layout>
      </I18nProvider>
    </div>
  )
}

export default Privacy;
