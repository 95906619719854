import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import '../styles/_questions.scss';


const Questions = () => {
  const initialActiveIndex = Array.from({ length: 6 }, (_, index) => ({
    index,
    isActive: false
  }));

  const [activeIndex, setIsActiveIndex] = useState(initialActiveIndex);

  const handleClick = (index) => {
    setIsActiveIndex((prev) => {
      return prev.map((item) => {
        if (item.index === index) {
          return { ...item, isActive: !item.isActive };
        }
        return { ...item, isActive: false };;
      });
    });
  };

  return (
    <section className='Question-section'>
      <div className='container'>
        <div className='app-haeding section-content feedbacks-heading'>
          <h2><Trans>Foire aux questions</Trans></h2>
          <p><Trans>Trouvez les réponses aux questions les plus récurrentes</Trans></p>
        </div>
        <div className="QuestionAccordion">
          <div className="accordion-wrapper">
            <div className={`accordion ${activeIndex[0].isActive ? "active-accordion" : 'inactive-accordion'}`}>
              <input type="checkbox" onChange={(e) => handleClick(0)} name="checkbox-1" id="check1" />
              <label className="accordion-label" htmlFor="check1"><Trans>🤔 Qu'est-ce que Repat Africa et quels sont ses objectifs ?</Trans></label>
              <div className="accordion-content">
                <p>
                  <Trans>
                  Repat Africa est un réseau qui rassemble des Africains, des membres de la diaspora et des personnes souhaitant investir ou s’installer sur le continent.

                  Il vise à faciliter le retour en Afrique, à promouvoir des investissements et à créer des emplois.

                  Le réseau se fonde sur la confiance, l’échange d’expériences et la collaboration entre ses membres.
                  </Trans>
                  </p>
              </div>
            </div>
            <div className={`accordion ${activeIndex[1].isActive ? "active-accordion" : 'inactive-accordion'}`}>
              <input type="checkbox" onChange={(e) => handleClick(1)} name="checkbox-2" id="check2" />
              <label className="accordion-label" htmlFor="check2"><Trans>✅ Quels sont les avantages d’adhérer à Repat Africa ?</Trans></label>
              <div className="accordion-content">
                <p><Trans>Les membres bénéficient d’un accès à un réseau de confiance via notre propre application mobile et de contacts dans divers secteurs d’activité. Ils peuvent participer à des événements exclusifs et à des voyages d’affaires en Afrique. Le réseau offre des opportunités d’investissement et de collaboration, ainsi que des formations pour progresser dans leur projet personnel ou professionnel. Ils profitent également d’un soutien collectif qui facilite la prise de décision et l’accès à de nouvelles opportunités.</Trans></p>
              </div>
            </div>
            <div className={`accordion ${activeIndex[2].isActive ? "active-accordion" : 'inactive-accordion'}`}>
              <input type="checkbox" onChange={(e) => handleClick(2)} name="checkbox-3" id="check3" />
              <label className="accordion-label" htmlFor="check3"><Trans>💵 Quel est le montant de l'adhésion ? À quoi sert-elle ?</Trans></label>
              <div className="accordion-content">
                <p><Trans>L'adhésion est de 500 EUR par an. Cette somme permet de soutenir la recherche et développement, la formation des membres, les partenariats, ainsi que l'organisation d'événements exclusifs.</Trans></p>
              </div>
            </div>
            <div className={`accordion ${activeIndex[3].isActive ? "active-accordion" : 'inactive-accordion'}`}>
              <input type="checkbox" onChange={(e) => handleClick(3)} name="checkbox-4" id="check4" />
              <label className="accordion-label" htmlFor="check4"><Trans>⌛️ Quand vais-je recevoir une réponse concernant ma candidature ?</Trans></label>
              <div className="accordion-content">
                <p><Trans>Dans les 48 heures après votre candidature.</Trans></p>
              </div>
            </div>
            <div className={`accordion ${activeIndex[4].isActive ? "active-accordion" : 'inactive-accordion'}`}>
              <input type="checkbox" onChange={(e) => handleClick(4)} name="checkbox-5" id="check5" />
              <label className="accordion-label" htmlFor="check5"><Trans>💳  Pourquoi faut-il ajouter ses informations bancaires avant la visioconférence ?</Trans></label>
              <div className="accordion-content">
                <p>
                  <Trans>
                    Remplir votre mandat de prélèvement avec vos informations bancaires est nécessaire pour compléter votre dossier de candidature. Une fois votre candidature acceptée, l'entretien par visioconférence réalisé, et votre accord donné, vous aurez immédiatement accès à la communauté et à tous les avantages de Repat Africa. Si votre candidature n'est finalement pas retenue, vos informations bancaires seront détruites.
                    Soyez rassuré, aucun prélèvement n'est effectué sans votre confirmation d'entrée dans la communauté, qui ne se fait qu'après l'entretien par visioconférence.
                    Notez que nous utilisons une plateforme sécurisée pour le traitement des mandats afin de protéger vos informations bancaires.
                  </Trans>
                </p>
              </div>
            </div>
            <div className={`accordion ${activeIndex[5].isActive ? "active-accordion" : 'inactive-accordion'}`}>
              <input type="checkbox" onChange={(e) => handleClick(5)} name="checkbox-6" id="check6" />
              <label className="accordion-label" htmlFor="check6"><Trans>🛩️ Qu'est-ce que le Repat Tour et comment se déroule-t-il ?</Trans></label>
              <div className="accordion-content">
                <p><Trans>Le Repat Tour est un voyage d’affaires organisé pour découvrir le terrain en Afrique. Il dure généralement 10 à 12 jours et se concentre sur une destination précise. Les participants visitent des sites d’affaires, rencontrent des entrepreneurs locaux et découvrent les opportunités du pays. Ce voyage favorise le networking sur place et aide à bâtir un réseau local solide.</Trans></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Questions;
